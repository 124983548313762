import React from 'react';
import { graphql } from 'gatsby'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import Seo from '../components/seo/seo'
import Img from 'gatsby-image'
import FAQItem from '../components/common/faqItem';
import SimpleSidebar from '../components/common/simpleSidebar';
import { useTranslation } from 'react-i18next'


const ServiceTemplate = ({ data }) => {

  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  return(
      <>
        <Seo
          title={data.markdownRemark.frontmatter.seoTitle}
          description={data.markdownRemark.frontmatter.seoDescription}
          seoImage={
            data.markdownRemark.frontmatter.seoImage.childImageSharp.resize.src
          }
        />
        <SiteNavigation />
        <div className='h-96 bg-gradient-to-r from-indigo-500 via-sky-500 to-emerald-500 relative'>
          <Img
          fluid={data.markdownRemark.frontmatter.seoImage.childImageSharp.fluid}
          style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
          }}
          imgStyle={{
              objectFit: 'cover', // Cover the entire div area
              objectPosition: '50% 50%', // Center the image within the div
              opacity: 0.2, // Optional: Set the opacity if you want to see anything behind it
          }}
          // className="image-wrapper-height-auto"
          alt={data.markdownRemark.frontmatter.title}
          />
          <div className='pt-8 max-w-7xl mx-auto absolute inset-0 px-16 md:px-24 xl:px-0 flex items-center'>
            <div className='md:w-2/3'>
                <h1 className="font-semibold text-white text-4xl md:text-6xl">
                    {data.markdownRemark.frontmatter.title}
                </h1>
                <p className="mt-4 text-white font-semibold">
                  {data.markdownRemark.frontmatter.description}
                </p>
            </div>
          </div>
        </div>
        <div className='md:max-w-7xl md:mx-auto px-16 xl:px-0 md:flex md:space-x-16 py-8'>
            <div className='md:w-1/3 flex flex-col space-y-4 pt-8'>
                <h2 className='font-semibold text-xl'>{ data.markdownRemark.frontmatter.sidebarTitle }</h2>
                <p className=''>{ data.markdownRemark.frontmatter.sidebarDesc }</p>
                <div className="flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                    </svg>
                    <p className=''>{ data.markdownRemark.frontmatter.sidebarDesc1 }</p>
                </div>
                
                <div className="flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                    </svg>
                    <p className=''>{ data.markdownRemark.frontmatter.sidebarDesc2 }</p>
                </div>
                <div className="flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <p className=''>{ data.markdownRemark.frontmatter.sidebarDesc3 }</p>
                </div>
                <div className="flex space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
                    </svg>
                    <p className=''>{ data.markdownRemark.frontmatter.sidebarDesc4 }</p>
                </div>
            </div>
            <div className='md:w-2/3 pt-8 md:pt-0'>
                <div className='bg-slate-50 rounded'
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
            </div>
        </div>
        <CommonFooter />
      </>
    )
  }

export default ServiceTemplate

export const funnelQuery = graphql`
  query Funnel($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        path
        translatedPath
        title
        description
        seoTitle
        seoDescription
        category
        sidebarTitle
        sidebarDesc
        sidebarDesc1
        sidebarDesc2
        sidebarDesc3
        sidebarDesc4
        seoImage {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 600, height: 400) {
              src
            }
          }
        }
      }
    }
  }
`
